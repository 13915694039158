import { useHistory,Redirect  } from "react-router-dom";
const HRRouteProduction = ({ isLoggedIn,roleUID, children }) => {
    const history = useHistory();
if (!isLoggedIn) {

   return <Redirect to='/unauthorized' />
}
else{
  if(roleUID==5)
  {
    return children;
  }
  else
  {
    return <Redirect to='/unauthorized' />
  }
}

};
export default HRRouteProduction;