import React,{ useState,useEffect} from "react";
import {useDispatch,useSelector} from 'react-redux'
import {apiAction,toastAction,toastActionAlert} from '../../../customRedux/actions/Actions'
import {toastConstant,apiConstant} from '../../../customRedux/constants/actionConstant'
import {apiPost,apiFormDatePost} from '../../../api/apiCommon'
import { useHistory } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const InternShip =()=>
{
   const reduxState=useSelector(state=>state);
   const dispatch=useDispatch();
   const history = useHistory();
   const [showModelViewer,setShowModelViewer]=useState();
   const [tempUserDetails,setTempUserDetails]=useState({});
   const [officeMail,setOfficeMail]=useState('');
   const [officePassword,setOfficePassword]=useState('');
   useEffect(() => {
    getEmpList();
 }, []);
 
 const [columnDef, setcolumnDef] = useState([
{headerName:'Name',field:'name'},
{headerName:'Contact Number',field:'mobile'},
{headerName:'Official Mail',field:'officialEmail'},
{headerName:'DOB',field:'DOB'},
{headerName:'Address',width:'210%',field:'address'},
{headerName:'Employee Type',field:'employmentType'},
{headerName:'Action',width:'210%', field:'employeeUID',cellRendererFramework:(params)=>(<><button  style={{backgroundColor:'#283179'}}type="button" onClick={(e)=>empEdit(params)}  className="btn btn-info btn-sm"><i className="fa fa-edit"></i></button>&nbsp;&nbsp;&nbsp;<button onClick={(e)=>empView(params)}  type="button" className="btn btn-success btn-sm"><i className="fa fa-eye"></i></button></>
 )}
]);
const [rowData, setrowData] = useState(null);
const [gridApi, setgridApi] = useState(null);
const getEmpList=async ()=>
{
 
  try{
      
      const response= await   apiPost('admin/getintershipemployeelist', {userUID:reduxState.userReducer.userUID,
              roleUID:reduxState.userReducer.roleUID});
      if(!response.data.error)
      {
          setrowData(response.data.empDetails);
        
          /* console.log('details',response.data.empDetails) */
          
      }
      else
      { 
          dispatch(toastAction(toastConstant.setToast,response.data.errorMessage));
          dispatch(toastAction(toastConstant.errorToast));
      }
     
   }
   catch(error) {
         dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
         dispatch(toastAction(toastConstant.errorToast));
         console.log(error);
  }
}

const onGridReady = (params) => {
    setgridApi(params.api);
    params.api.sizeColumnsToFit();
}
const onFilterTextChange=(e)=>{gridApi.setQuickFilter(e.target.value)}

const empEdit=(params)=>
  {
    /* setTempUserDetails(params.data);
    setShowModelViewer(true);
    setOfficeMail(params.data.officialEmail);
    setOfficePassword(params.data.password); */
    history.push("/corporate/taxinfo/EditEmployee/"+params.value)
    console.log(params.value);
  } 
  const empView=(params)=>
  {
    history.push("/corporate/taxinfo/viewemployee/"+params.value)
  }
  const empSubmit=async (event)=>
{
  event.preventDefault();

    try{
     

      var officialMail=officeMail;
      var officialPassword=officePassword;
      var empUID=tempUserDetails.employeeUID;

      const response= await  apiPost('admin/updateinternshipemp', {userUID:reduxState.userReducer.userUID,
      roleUID:reduxState.userReducer.roleUID,
      empUID:empUID,
      officialMail:officialMail,
      password:officialPassword});

      if(!response.data.error && response.data.code==0)
      {
        setShowModelViewer(false)
        dispatch(toastAction(toastConstant.setToast,response.data.message));
        dispatch(toastAction(toastConstant.successToast));
        setOfficeMail('');
        setOfficePassword('');
        gridApi.showLoadingOverlay();
        getEmpList();
        gridApi.hideOverlay();

        
        
      }
      else
      { setShowModelViewer(false)
        dispatch(toastAction(toastConstant.setToast,response.data.code==1?response.data.errorMessage:'Internal Server Error'));
        dispatch(toastAction(toastConstant.errorToast));
      }
    
  }
  catch(error) {
        dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
        dispatch(toastAction(toastConstant.errorToast));
        console.log(error);
  }
}
   return(
    <div className="wrapper">
    <div className="container-fluid">
        <div className="alert alert-success" id="msg_text" style={{display:'none'}}>
        </div>
        <div className="row">
            <div className="col-sm-12">
                <div className="page-title-box">
                    <div className="btn-group pull-right">

                    </div>
                    <h4 className="page-title">Internship Employees</h4>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">

                        <h4 className="mt-0 header-title">List Of Internship Employees</h4>
                        <div className="row" style={{paddingBottom: '8px'}}>
 <div className="col-sm-5">
 </div>
 <div className="col-sm-4">
   </div>
 <div className="col-sm-3">
 <input   className="form-control"type="search" style={{float:'right',marginRight:'10%'}}  onChange={onFilterTextChange} placeholder="Search"/>
 </div>
</div>
                        <div className="ag-theme-alpine" style={{height:450, width: 'auto'}}>
                        
                        <AgGridReact
       defaultColDef={{
         editable: false,
         enableRowGroup: true,
         enablePivot: true,
         enableValue: true,
         sortable: true,
         resizable: true,
         filter: true,
         floatingFilter: true,
         filter: 'agTextColumnFilter',
         minWidth: 100,
         cellStyle:{textAlign:'left'}
       }}
       suppressRowClickSelection={true}
       groupSelectsChildren={true}
       debug={true}
       rowSelection={'multiple'}
      /*  rowGroupPanelShow={'always'} */
       pivotPanelShow={'always'}
       enableRangeSelection={true}
       pagination={true}
       onGridReady={onGridReady}
       columnDefs={columnDef}
       rowData={rowData}
   />
  </div>

                    </div>
                </div>
            </div> 
        </div> 
    </div>
    <Modal
      show={showModelViewer}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         EMPLOYEE ACTION
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form onSubmit={empSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                autoFocus
                value={tempUserDetails.name}
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="number"
                placeholder="enter mobile number"
                autoFocus
                value={tempUserDetails.contactNo}
                readOnly
              />
            </Form.Group>
           
            
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Official Mail</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Employee Official Mail"
                autoFocus
                value={officeMail}
                onChange={e=>setOfficeMail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Employee Password"
                autoFocus
                value={officePassword}
                onChange={e=>setOfficePassword(e.target.value)}
                required
              />
            </Form.Group>

            

            
           <center>
           <button  style={{backgroundColor:'#283179'}}    type="submit" className="btn btn-success waves-effect waves-light">
                     Submit
                     </button> 
           </center>
           
          </Form>
      </Modal.Body>
      <Modal.Footer>
      <button   onClick={() => setShowModelViewer(false)} type="button" className="btn btn-danger waves-effect waves-light">
                     Close
                     </button> 
      </Modal.Footer>
    </Modal>
</div>
   );
}
export default InternShip;