import React,{ useState,useEffect} from "react";
import {useDispatch,useSelector} from 'react-redux'
import {apiAction,toastAction,toastActionAlert} from '../../../customRedux/actions/Actions'
import {toastConstant,apiConstant} from '../../../customRedux/constants/actionConstant'
import {apiPost,apiFormDatePost} from '../../../api/apiCommon'
import { useHistory,useParams  } from "react-router-dom";


const ViewTimesheet =()=>
{
    const { id } = useParams();
    const reduxState=useSelector(state=>state);
    const dispatch=useDispatch();
    const history = useHistory();
    const [rowData, setrowData] = useState({});
   
    
    
    useEffect(() => {
        getViewList();
      }, []);

      const getViewList=async ()=>
      {
       
        try{
      
            const response= await   apiPost('admin/viewtimesheetdata', {userUID:reduxState.userReducer.userUID,
                    roleUID:reduxState.userReducer.roleUID,
                    id:id});
            if(!response.data.error)
            {
                setrowData(response.data.empDetails[0]);
               
              
                /* console.log('details',response.data.empDetails) */
                
            }
            else
            { 
                dispatch(toastAction(toastConstant.setToast,response.data.errorMessage));
                dispatch(toastAction(toastConstant.errorToast));
            }
           
         }
         catch(error) {
               dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
               dispatch(toastAction(toastConstant.errorToast));
               console.log(error);
        }
      }
   return(
    <div className="wrapper">
    <div className="container-fluid">

       
    <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            
                            <h4 className="page-title">View Employee Details</h4>
                            
                            <a href="#" onClick={e=>history.goBack()} style={{margin:'1%', float:'right'}} className="btn btn-sm btn-primary">Back</a>
                        </div>
                    </div>
                </div>
       
        <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                            <h4 className="mt-0 header-title">Personal Details </h4>
                            <div className="row">
                                    <div className="col-md-2">
                                        <div className="p-20">
                                               
                                               
                                                <div className="form-group">
                                                    <label>LogIN :</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>LogOut :</label>
                                                </div>
                                                
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="p-20">
                                               

                                                <div className="form-group">
                                                    <label>{rowData.loggedIN}</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>{rowData.loggedOUT}</label>
                                                </div>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="p-20">
                                                <div className="form-group">
                                                    <label>Date :</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>Task:</label>
                                                </div>
                                       {/*          <div className="form-group">
                                                    <label>Previous exp if any :</label>
                                                </div>
                                                <div className="form-group">
                                                     <label>State :</label>
                                                </div> */}
                                               
                                        </div>
                                    </div>


                                    <div className="col-md-2">
                                        <div className="p-20">
                                                <div className="form-group">
                                                    <label>{rowData.currentdate}</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>{rowData.task}</label>
                                                </div>
                                         {/*        <div className="form-group">
                                                    <label>{rowData.previousExperience}</label>
                                                </div>
                                                <div className="form-group">
                                                 <label>{rowData.stateName}</label>
                                              </div> */}
                                                
                                        </div>
                                    </div>

                                   

                               
                                </div> 
                            </div>
                        </div>
                    </div> 

                  
                  
                    </div>
               
            </div> 
        </div>
   );
}
export default ViewTimesheet;