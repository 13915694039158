import { useState,useEffect} from "react";
import {useDispatch,useSelector} from 'react-redux'
import {apiAction,toastAction,toastActionAlert} from '../../../customRedux/actions/Actions'
import {toastConstant,apiConstant} from '../../../customRedux/constants/actionConstant'
import {apiPost,apiFormDatePost,setLoggedInuser} from '../../../api/apiCommon'
import { useHistory } from "react-router-dom";

const Dashboard =()=>
{
    const reduxState=useSelector(state=>state);
    const dispatch=useDispatch();
    const history = useHistory();
    useEffect(() => {
         getEmpCount();
         getBirthdayList();
      }, []);

   const [empCountDetails,setempCountDetails]=useState({});
   const [empBirthDayDetails,setEmpBirthDayDetails]=useState([]);
   const getEmpCount =async ()=>
   {
    try{
             
        const response= await  apiPost('getdashboardempcount', {userUID:reduxState.userReducer.userUID,
                roleUID:reduxState.userReducer.roleUID});
        if(!response.data.error)
        {
            setempCountDetails(response.data.empCountDetails);
            
        }
        else
        { 
            dispatch(toastAction(toastConstant.setToast,response.data.errorMessage));
            dispatch(toastAction(toastConstant.errorToast));
        }
       
     }
     catch(error) {
           dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
           dispatch(toastAction(toastConstant.errorToast));
           console.log(error);
    }
   }

   const getBirthdayList =async ()=>
   {
    try{
             
        const response= await  apiPost('getbirthdaylist', {userUID:reduxState.userReducer.userUID,
                roleUID:reduxState.userReducer.roleUID});
        if(!response.data.error)
        {
            setEmpBirthDayDetails(response.data.empBirthdaDetails);
            
        }
        else
        { 
            dispatch(toastAction(toastConstant.setToast,response.data.errorMessage));
            dispatch(toastAction(toastConstant.errorToast));
        }
       
     }
     catch(error) {
           dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
           dispatch(toastAction(toastConstant.errorToast));
           console.log(error);
    }
   }
   return(
    <div className="wrapper">
    <div className="container-fluid">

       
        <div className="row">
            <div className="col-sm-12">
                <div className="page-title-box">
                
                    <h4 className="page-title">Dashboard</h4>
                </div>
            </div>
        </div>
       
        <div className="row">
            <div className="col-xl-12">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex flex-row">
                                    <div className="col-3 align-self-center">
                                        <div className="round">
                                        <i className="mdi mdi-account-multiple-plus"></i>
                                        </div>
                                    </div>
                                    <div className="col-9 align-self-center text-right">
                                        <div className="m-l-10">
                                            <h5 className="mt-0">{empCountDetails.Total}</h5>
                                            <p className="mb-0 text-muted">Total Employees </p>
                                        </div>
                                    </div>                                                                                          
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex flex-row">
                                    <div className="col-3 align-self-center">
                                        <div className="round">
                                            <i className="mdi mdi-account-multiple-plus"></i>
                                        </div>
                                    </div>
                                    <div className="col-9 text-right align-self-center">
                                        <div className="m-l-10 ">
                                            <h5 className="mt-0">{empCountDetails.onrollteamTotal}</h5>
                                            <p className="mb-0 text-muted">OnRoll Team</p>
                                        </div>
                                    </div>                                                                                                                
                                </div>
                               
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="search-type-arrow"></div>
                                <div className="d-flex flex-row">
                                    <div className="col-3 align-self-center">
                                        <div className="round ">
                                        <i className="mdi mdi-account-multiple-plus"></i>
                                        </div>
                                    </div>
                                    <div className="col-9 align-self-center text-right">
                                        <div className="m-l-10 ">
                                            <h5 className="mt-0">{empCountDetails.internshipTotal}</h5>
                                            <p className="mb-0 text-muted">Internship Team</p>
                                        </div>
                                    </div>                                                                
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

               
            </div>

        </div>

                
          
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body new-user">
                                <h5 className="header-title mb-4 mt-0">Upcoming Birthdays</h5>
                                <div className="table-responsive">
                                    <table className="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th className="border-top-0">Employee Name</th>
                                                <th className="border-top-0">Official Email</th>
                                                <th className="border-top-0">Date of Birth</th>
                                                <th className="border-top-0">Upcoming Birthday's</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {empBirthDayDetails.map((e, key) => {
        return <tr  key={key}><td>{e.name}</td><td>{e.officialEmail}</td><td>{e.DOB}</td><td className="birthDay" style={{color:e.colorCode}}><b>{e.BIRTHDAY}</b></td></tr>;
    })}  
 
    {empBirthDayDetails.length==0 ? (
       <tr><td colSpan={4} style={{ textAlign:'center' }}>No Birthday List Found Upcoming 30 days</td></tr>
      ) : (""
      )}
                      
                                   
                                                      
                                    
                                    </tbody>
                                       
                                      
                                    </table>                                                
                                </div>
                            </div>
                        </div>
                    </div>
             
                </div> 
            </div> 
        </div>
   );
}
export default Dashboard;