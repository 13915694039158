/* import React,{ useState,useEffect} from 'react'; */
import {useDispatch,useSelector} from 'react-redux'
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';
import Login from '../common/Login';
import RegistrationSuccess from '../common/success';
import Newregistration from '../new-registration/Newregistration';
import NewregistrationDemo from '../new-registration/NewregistrationDemo';
import Userprofile from '../userprofile/Userprofile';
import CorporateRoute from '../corporate/corporateRoute'
import CorporateLogin from '../corporate/CorporateLogin'
import AdminRouteProduction from '../routes/AdminRouteProduction'
import HRRouteProduction from '../routes/HRRouteProduction'
import PageNotFound from '../extraPages/pagenotfound'
import UnauthorizedPage from '../extraPages/unautherized'
import HRroute from '../corporate/HR/route/HRroute'
import TaxinfoRoute from '../corporate/Taxinfo/route/TaxinfoRoute'
import TaxinfoRouteProduction from '../routes/TaxinfoRouteProduction'
import EmployeeRoute from '../corporate/Employee/route/EmployeeRoute';
import ServerAdminRoute from '../corporate/ServerAdmin/route/ServerAdminroute';
import EmployeeRouteProduction from './EmployeeRouteProduction';
import ServerAdminRouteProduction from './ServerAdminRouteProduction';
const Routes = () => {
    const reduxState=useSelector(state=>state);
    const dispatch=useDispatch();
	

   
    return (
        <BrowserRouter basename=''>
            <HashRouter>
                <Switch>
                   <Route exact path="/"  component={CorporateLogin} />
                   <Route exact path="/employee"  component={Login} />
                   <Route exact path='/newemployeement' component={Newregistration}/>
                   <Route exact path='/newemployeement-demo' component={NewregistrationDemo}/>
                   <Route exact path='/userprofile/:id' component={Userprofile}/>
                   <Route exact path="/success"  component={RegistrationSuccess} />
                   <Route exact path="/pagenotfound"  component={PageNotFound} />
                   <Route exact path="/unauthorized"  component={UnauthorizedPage} />
                   <Route exact path="/corporate"  component={CorporateLogin} />
                   <Route exact path="/corporate/login"  component={CorporateLogin} />
                   <Route  path='/corporate/admin/'>
                    <AdminRouteProduction isLoggedIn={reduxState.userReducer.isLoggedIn} roleUID={reduxState.userReducer.roleUID}>
                         <CorporateRoute /> 
                    </AdminRouteProduction>
                   </Route>
                   <Route  path='/corporate/hr/'>
                    <HRRouteProduction isLoggedIn={reduxState.userReducer.isLoggedIn} roleUID={reduxState.userReducer.roleUID}>
                        <HRroute/>
                    </HRRouteProduction>
                   
                   </Route>
                   <Route  path='/corporate/taxinfo/'>
                   <TaxinfoRouteProduction isLoggedIn={reduxState.userReducer.isLoggedIn} roleUID={reduxState.userReducer.roleUID}>
                        <TaxinfoRoute/>
                    </TaxinfoRouteProduction>
                   </Route>
                   <Route  path='/corporate/employee/'>
                   <EmployeeRouteProduction isLoggedIn={reduxState.userReducer.isLoggedIn} roleUID={reduxState.userReducer.roleUID}>
                        <EmployeeRoute/>
                    </EmployeeRouteProduction>
                   </Route>
                   <Route  path='/corporate/server/'>
                   <ServerAdminRouteProduction isLoggedIn={reduxState.userReducer.isLoggedIn} roleUID={reduxState.userReducer.roleUID}>
                        <ServerAdminRoute/>
                    </ServerAdminRouteProduction>
                   </Route>
                   <Route path='*' component={PageNotFound} />
                   
                </Switch>
            </HashRouter>
        </BrowserRouter>

    );
};

export default Routes;