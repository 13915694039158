import React from "react";
import { BrowserRouter, Route, Switch, HashRouter,Redirect } from 'react-router-dom';
import ServerComponent from '../index'
const ServerAdminRoute=()=>
{ return (
        <Switch>
            <Route  path="/corporate/server/"  component={ServerComponent} />
            <Route path='*' render={() => (<Redirect to="/pagenotfound" />)}  />
        </Switch>
    );

}
export default ServerAdminRoute;