import React from "react";
import { BrowserRouter, Route, Switch, HashRouter,Redirect } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Dashboard from './dashboard/dashboard';
import Profile from "./profile/profile";
import TimeSheet from "./TimeSheet/timesheet"
/* import InternShip from './internship/internship';
import OnRoll from './onroll/onroll';
import EditIntern from "./internship/editinternship";
import EditOnroll from "./onroll/editonroll";
import EmployeeView from "../component/employeeview/employeeview"; */
const EmployeeComponent=()=>
{  return (
    <><>
        <Header />
        <Switch>
            <Route exact path="/corporate/employee/dashboard" component={Dashboard} />
             <Route exact path="/corporate/employee/profile" component={Profile} />
           <Route exact path="/corporate/employee/timesheet" component={TimeSheet} /> 
           {/*  <Route exact path="/corporate/employee/onroll" component={OnRoll} />
            <Route exact path="/corporate/employee/editintern/:id" component={EditIntern} />
            <Route exact path="/corporate/employee/editonroll/:id" component={EditOnroll} /> 
            <Route exact path="/corporate/employee/viewemployee/:id" component={EmployeeView} />   */}
            <Route path='*' render={() => (<Redirect to="/pagenotfound" />)}  />
        </Switch>
    </><Footer /></>
    
    
   );
}

export default EmployeeComponent;