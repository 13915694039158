import React,{ useState,useEffect} from "react";
import {useDispatch,useSelector} from 'react-redux'
import {apiAction,toastAction,toastActionAlert} from '../../../customRedux/actions/Actions'
import {toastConstant,apiConstant} from '../../../customRedux/constants/actionConstant'
import {apiPost,apiFormDatePost} from '../../../api/apiCommon'
import { useHistory } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from 'moment';

const TimeSheet =()=>
{
   const reduxState=useSelector(state=>state);
   const dispatch=useDispatch();
   const history = useHistory();
   const [showModelViewer,setShowModelViewer]=useState();
   const [employeeUID,setemployeeUID]=useState();
   const [currentdate,setcurrentdate]=useState(moment().format('YYYY-MM-DD'));
   const [loggedIN,setloggedIN]=useState('');
   const [loggedOUT,setloggedOUT]=useState('');
   const [taskk,settask]=useState('');
     //date variable
     const[dateList,setdateList]=useState([]);

     const getdateList= async()=>{
          try{
            
             const returnapi= await  apiPost('/admin/timesheetdate',{userUID:reduxState.userReducer.userUID,
              roleUID:reduxState.userReducer.roleUID});
             if(!returnapi.data.error)
             {
              setdateList(returnapi.data.timesheetdate
                );
             }
            
          }
          catch(error) {
             console.log(error);
         }
       
     }
   useEffect(() => {
    getEmpList();
    getdateList();
 }, []);
 
 const [columnDef, setcolumnDef] = useState([
//{headerName:'UserUID',field:'userUID'},
//{headerName:'EmpName',field:'name'},
{headerName:'LogIN',field:'loggedIN'},
{headerName:'LogOut',field:'loggedOUT'},
{headerName:'Task',field:'task'},
/* {headerName:'Action',width:'210%', field:'employeeUID',cellRendererFramework:(params)=>(<><button  style={{backgroundColor:'#283179'}} type="button" onClick={(e)=>empEdit(params)}  className="btn btn-info btn-sm"><i className="fa fa-edit"></i></button>&nbsp;&nbsp;&nbsp;<button onClick={(e)=>empView(params)}  type="button" className="btn btn-success btn-sm"><i className="fa fa-eye"></i></button></>

 )} */
]);
const [rowData, setrowData] = useState(null);
const [gridApi, setgridApi] = useState(null);

const getEmpList=async ()=>
{
 
  try{
      
      const response= await   apiPost('admin/gettimesheetdata', {userUID:reduxState.userReducer.userUID,
              roleUID:reduxState.userReducer.roleUID});
      if(!response.data.error)
      {
          setrowData(response.data.empDetails);
        
          /* console.log('details',response.data.empDetails) */
          
      }
      else
      { 
          dispatch(toastAction(toastConstant.setToast,response.data.errorMessage));
          dispatch(toastAction(toastConstant.errorToast));
      }
     
   }
   catch(error) {
         dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
         dispatch(toastAction(toastConstant.errorToast));
         console.log(error);
  }
}


const onGridReady = (params) => {
    setgridApi(params.api);
    params.api.sizeColumnsToFit();
}




const [formData, setFormData] = useState({});
const handleClick=(event)=>
  {
    setFormData('');
    setShowModelViewer(true);
  
  }

  /* const onSubmit = async (data) => {
    console.log(data);
     var currentdate=currentdate;
     var loggedIN=loggedIN;
     var  loggedOUT=loggedOUT;
     var  task=task;
     var  empUID=formData.employeeUID;
    // Handle form submission here
    const res = await fetch("http://127.0.0.1:8000/api/admin/addtimesheet", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userUID:reduxState.userReducer.userUID,
        roleUID:reduxState.userReducer.roleUID,
        empUID:empUID,
        currentdate:currentdate,
        loggedIN:loggedIN,
        loggedOUT:loggedOUT,
        task:task,
      }),
    });
    console.log(data);
  }; */
  const onChangeDate = (e) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
    setcurrentdate(newDate)
    console.log(newDate); //always log "1970-01-01"
  };

  const empSubmit=async (event)=>
{
  event.preventDefault();

    try{
     

        var date=date;
        var logindate=logindate;
        var  logoutdate=logoutdate;
        var  task=task;
        var  empUID=empUID;

      const response= await  apiPost('admin/addtimesheet', {userUID:reduxState.userReducer.userUID,
      roleUID:reduxState.userReducer.roleUID,
      empUID:employeeUID,
      date:currentdate,
      logindate:loggedIN,
      logoutdate:loggedOUT,
      task:taskk});

      if(!response.data.error && response.data.code==0)
      {
        setShowModelViewer(false)
        dispatch(toastAction(toastConstant.setToast,response.data.message));
        dispatch(toastAction(toastConstant.successToast));
        setcurrentdate('');
        setemployeeUID('');
        setloggedIN('');
        setloggedOUT('');
        settask('');
        gridApi.showLoadingOverlay();
        getEmpList();
        gridApi.hideOverlay();

        
        
      }
      else
      { setShowModelViewer(false)
        dispatch(toastAction(toastConstant.setToast,response.data.code==1?response.data.errorMessage:'Internal Server Error'));
        dispatch(toastAction(toastConstant.errorToast));
      }
    
  }
  catch(error) {
        dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
        dispatch(toastAction(toastConstant.errorToast));
        console.log(error);
  }

}
   return(
    <div className="wrapper">
    <div className="container-fluid">
        <div className="alert alert-success" id="msg_text" style={{display:'none'}}>
        </div>
        <div className="row">
            <div className="col-sm-12">
                <div className="page-title-box">
                    <div className="btn-group pull-right">

                    </div>
                    <h4 className="page-title">TimeSheet </h4>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">

                        <h4 className="mt-0 header-title">List Of Timesheet</h4>
                        <div className="row" style={{paddingBottom: '8px'}}>
 <div className="col-sm-5">
 </div>
 <div className="col-sm-4">
   </div>
 <div className="col-sm-3">
 <button  style={{backgroundColor:'#283179',marginLeft:'50%'}} onClick={(e)=>handleClick()}   type="button" className="btn btn-success waves-effect waves-light">
                     Add Timesheet
                     </button> 
 </div>
</div>
                        <div className="ag-theme-alpine" style={{height:450, width: 'auto'}}>
                        
                        <AgGridReact
       defaultColDef={{
         editable: false,
         enableRowGroup: true,
         enablePivot: true,
         enableValue: true,
         sortable: true,
         resizable: true,
         filter: true,
         floatingFilter: true,
         filter: 'agTextColumnFilter',
         minWidth: 100,
         cellStyle:{textAlign:'left'}
       }}
       suppressRowClickSelection={true}
       groupSelectsChildren={true}
       debug={true}
       rowSelection={'multiple'}
      /*  rowGroupPanelShow={'always'} */
       pivotPanelShow={'always'}
       enableRangeSelection={true}
       pagination={true}
       onGridReady={onGridReady}
       columnDefs={columnDef}
       rowData={rowData}
   />
  </div>

                    </div>
                </div>
            </div> 
        </div> 
    </div>
    <Modal
      show={showModelViewer}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         TIME SHEET
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form onSubmit={empSubmit}>
            <Form.Group className="mb-3" style={{ marginRight: '50%'}} controlId="exampleForm.ControlInput1">
              <Form.Label>Date</Form.Label>
     {/*          <Form.Control
                type="date"
                placeholder="Please enter Date"
                autoFocus
                value={currentdate}
                onChange={onChangeDate}
              /> */}
                       <div className="form-group">
              
               <select className="form-control" name="stateUID"   tabIndex="11" required>
              
               {dateList.map((e, key) => {
        return <option key={key} value={e}>{e}</option>;
    })}
            </select>
         </div>
            </Form.Group>
            <Form.Group className="mb-1" controlId="company">
                <Form.Label>Login Time</Form.Label>
                <Form.Control
                
                  style={{ width: '45%' }}
                  type="text"
                  
                  placeholder="Enter Login Time"
                
                  value={loggedIN}
                  onChange={e=>setloggedIN(e.target.value)}
                  autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-1" style={{ marginLeft: '50%', marginTop: '-71px' }} controlId="address">
                <Form.Label>Logout Time</Form.Label>
                <Form.Control
                   type="text"
                 
                  value={loggedOUT}
                  onChange={e=>setloggedOUT(e.target.value)}
                  placeholder="Enter Logout Time"
                  autoFocus
                />
              </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Task</Form.Label>
              <Form.Control 
                type="text"
                placeholder="Enter Employee task"
                autoFocus
                value={taskk}
                  onChange={e=>settask(e.target.value)}
                required
              />
            </Form.Group>

            

            
           <center>
           <button  style={{backgroundColor:'#283179'}}    type="submit" className="btn btn-success waves-effect waves-light">
                     Submit
                     </button> 
           </center>
           
          </Form>
      </Modal.Body>
      <Modal.Footer>
      <button   onClick={() => setShowModelViewer(false)} type="button" className="btn btn-danger waves-effect waves-light">
                     Close
                     </button> 
      </Modal.Footer>
    </Modal>
</div>
   );
}
export default TimeSheet;