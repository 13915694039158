import React,{ ChangeEvent,useState,useEffect} from "react";
import {useDispatch,useSelector} from 'react-redux'
import {apiAction,toastAction,toastActionAlert} from '../../../customRedux/actions/Actions'
import {toastConstant,apiConstant} from '../../../customRedux/constants/actionConstant'
import {apiPost,apiFormDatePost} from '../../../api/apiCommon'
import { useHistory,useParams } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
//import { AgGridReact } from 'ag-grid-react';
//import 'ag-grid-community/dist/styles/ag-grid.css';
//import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { saveAs } from 'file-saver'

const docFileTypes = ["PDF", "DOCX","DOC"];
const imgFileTypes = ["JPG","JPEG", "PNG"];

const Profile =()=>
{
   //const { id } = useParams();
   const reduxState=useSelector(state=>state);
   const dispatch=useDispatch();
   const history = useHistory();
   const [showModelView,setshowModelView]=useState();
   const [showModelViewer,setShowModelViewer]=useState();
   const [photoModelViewer,setphotoModelViewer]=useState();
   //const [tempUserDetails,setTempUserDetails]=useState({});
   const [resumeTitle,setresumeTitle]=useState('');
   const [resumeFileName,setresumeFileName]=useState('');
   const [photoFileName,setphotoFileName]=useState('');
   const [show, setShow] = useState(false);
   //const [id, setid] = useState('');
   const [rowData, setrowData] = useState({});
   const [imageDataURL, setimageDataURL] = useState('');
   //const [filePath, setFilePath] = useState(process.env.REACT_APP_FILE_URL);
   const [imgViewFile, setImgViewFile] = useState(null);
   const [resumeFlag, setResumeFlag] = useState(0);

   const handleview=(URL)=>
  {
    setImgViewFile(URL);
   // setImgViewFile(true);
   setshowModelView(true);
  
  }


  const downloadImage = async (url, name) => {
   
    saveAs(url, name)

    
  }

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   useEffect(() => {
   getEmpList(); 
 }, []);
 
const [columnDef, setcolumnDef] = useState([
{headerName:'ResumeUID',field:'resumeUID'},
{headerName:'UserUID',field:'UserUID'},
{headerName:'ResumeTitle',field:'resumeTitle'},
{headerName:'ResumeFileName',field:'resumeFileName'},

/* {headerName:'Action',width:'210%', field:'employeeUID',cellRendererFramework:(params)=>(<><button  style={{backgroundColor:'#283179'}}type="button" onClick={(e)=>empEdit(params)}  className="btn btn-info btn-sm"><i className="fa fa-edit"></i></button>&nbsp;&nbsp;&nbsp;<button onClick={(e)=>empView(params)}  type="button" className="btn btn-success btn-sm"><i className="fa fa-eye"></i></button></>
 )} */
]);

/* const [gridApi, setgridApi] = useState(null); */

const getEmpList=async ()=>
{
 
  try
  {
    
        const response= await   apiPost('admin/getemployeebyid', {userUID:reduxState.userReducer.userUID,
        roleUID:reduxState.userReducer.roleUID,id:reduxState.userReducer.employeeUID
        });
            //console.log('hai',id);
        if(!response.data.error)
        {
        console.log(response.data);
        setrowData(response.data.empDetails[0]);
        setimageDataURL(response.data.ImageURL);
    
            
        }
    else
    { 
        dispatch(toastAction(toastConstant.setToast,response.data.errorMessage));
        dispatch(toastAction(toastConstant.errorToast));
    }
   
 }
 catch(error) {
       dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
       dispatch(toastAction(toastConstant.errorToast));
       console.log(error);
}
}
const [formData, setFormData] = useState({});
const handleClick=(event)=>
  {
    setFormData('');
    setShowModelViewer(true);
  
  
  }
  const handle=(event)=>
  {
    setFormData('');
    setphotoModelViewer(true);
  
  }
/* const onGridReady = (params) => {
    setgridApi(params.api);
    params.api.sizeColumnsToFit();
}
const onFilterTextChange=(e)=>{gridApi.setQuickFilter(e.target.value)}
 */
const empEdit=(params)=>
  {
    /* setTempUserDetails(params.data);
    setShowModelViewer(true);
    setOfficeMail(params.data.officialEmail);
    setOfficePassword(params.data.password); */
    history.push("/corporate/employee/editintern/"+params.value)
    console.log(params.value);
  } 
  const empView=(params)=>
  {
    history.push("/corporate/employee/viewemployee/"+params.value)
  }
  const empSubmit=async (event)=>
{
  event.preventDefault();

    try{
     

      let formData = new FormData(); 
      formData.append('userUID',reduxState.userReducer.userUID);
      formData.append('roleUID',reduxState.userReducer.roleUID);
      formData.append('resumeTitle',resumeTitle);
      formData.append('resumeFileName', resumeFileName? resumeFileName[0] : "");
      const response= await  apiPost('admin/addresumeupload', formData);

      if(!response.data.error && response.data.code==0)
      {
        setShowModelViewer(false)
        dispatch(toastAction(toastConstant.setToast,response.data.message));
        dispatch(toastAction(toastConstant.successToast));
        setresumeTitle('');
        setresumeFileName('');
       /*  gridApi.showLoadingOverlay();
        getEmpList();
        gridApi.hideOverlay(); */

        
        
      }
      else
      { setShowModelViewer(false)
        dispatch(toastAction(toastConstant.setToast,response.data.code==1?response.data.errorMessage:'Internal Server Error'));
        dispatch(toastAction(toastConstant.errorToast));
      }
    
  }
  catch(error) {
        dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
        dispatch(toastAction(toastConstant.errorToast));
        console.log(error);
  }
}

const handleFileChange = (file) => {
  
  if(file.length!=0){setresumeFileName(file)}
};
const handleFile = (file) => {
  
  if(file.length!=0){setphotoFileName(file)}
};

const Submit=async (event)=>
{
  event.preventDefault();

    try{
     

      let formData = new FormData(); 
      formData.append('userUID',reduxState.userReducer.userUID);
      formData.append('roleUID',reduxState.userReducer.roleUID);
      formData.append('photoFileName', photoFileName? photoFileName[0] : "");
      const response= await  apiPost('admin/addphoto', formData);

      if(!response.data.error && response.data.code==0)
      {
        setphotoModelViewer(false)
        dispatch(toastAction(toastConstant.setToast,response.data.message));
        dispatch(toastAction(toastConstant.successToast));
        setphotoFileName('');
       /*  gridApi.showLoadingOverlay();
        getEmpList();
        gridApi.hideOverlay(); */

        
        
      }
      else
      { setphotoModelViewer(false)
        dispatch(toastAction(toastConstant.setToast,response.data.code==1?response.data.errorMessage:'Internal Server Error'));
        dispatch(toastAction(toastConstant.errorToast));
      }
    
  }
  catch(error) {
        dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
        dispatch(toastAction(toastConstant.errorToast));
        console.log(error);
  }
}

/* const downloadImage = async (url, name) => {
   
  saveAs(url, name)

  
} */
   return(
    <div className="wrapper">
    <div className="container-fluid">
        <div className="alert alert-success" id="msg_text" style={{display:'none'}}>
        </div>
        <div className="row">
            <div className="col-sm-12">
                <div className="page-title-box">
                    <div className="btn-group pull-right">

                    </div>
                    <h4 className="page-title"> Employee Profile</h4>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">

                        <h4 className="mt-0 header-title">Employees Profile</h4>
                        <div className="row" style={{paddingBottom: '8px'}}>
 <div className="col-sm-5">
 </div>
 <div className="col-sm-4">
   </div>
   <div className="btn">
   <button type="button" class="btn btn-success" onClick={(e)=>handleClick()}>Upload resume</button>
   </div>
   <div className="btn">
   <button type="button" class="btn btn-success"  onClick={(e)=>handle()} >Add photo</button>
   </div>
  
 {/* <div className="col-sm-3">
 <input   className="form-control"type="search" style={{float:'right',marginRight:'10%'}}  onChange={onFilterTextChange} placeholder="Search"/>
 </div> */}
</div>



                    </div>
                </div>
            </div> 
        </div> 
        <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                            <h4 className="mt-0 header-title">Personal Details </h4>
                            <div className="row">
                                    <div className="col-md-2">
                                        <div className="p-20">
                                                <div className="form-group">
                                                    <label>Name : </label>
                                                </div>
                                                <div className="form-group">
                                                    <label>Parents Contact no :</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>Education qualifications :</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>Permanent Address :</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>Pincode :</label>
                                                </div>
                                                
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="p-20">
                                                <div className="form-group">
                                                    <label>{rowData.name}</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>{rowData.pmobile}</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>{rowData.qualification}</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>{rowData.address}</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>{rowData.pincode}</label>
                                                </div>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="p-20">
                                                <div className="form-group">
                                                    <label>Date Of Birth :</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>Pan Card no :</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>Previous exp if any :</label>
                                                </div>
                                                <div className="form-group">
                                                     <label>State :</label>
                                                </div>
                                                <div className="form-group">
                                                     <label>Date Of Join :</label>
                                                </div>
                                               
                                        </div>
                                    </div>


                                    <div className="col-md-2">
                                        <div className="p-20">
                                                <div className="form-group">
                                                    <label>{rowData.DOB}</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>{rowData.pan}</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>{rowData.previousExperience}</label>
                                                </div>
                                                <div className="form-group">
                                                 <label>{rowData.stateName}</label>
                                                  </div>
                                                 <div className="form-group">
                                                  <label>{rowData.DOJ}</label>
                                                 </div>
                                                
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="p-20">
                                                <div className="form-group">
                                                <label>Contact no :</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>AADHAR No :</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>Personal Email :</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>Official Email :</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>Expected Join Date :</label>
                                                </div>
                                               
                                               
                                        </div>
                                    </div> 

                                    <div className="col-md-2">
                                        <div className="p-20">
                                                <div className="form-group">
                                                <label>{rowData.mobile}</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>{rowData.aadhar}</label>
                                                </div>
                                                <div className="form-group">
                                            <label style={{width: '100%'}}>{rowData.personalEmail}</label>
                                                </div>
                                                <div className="form-group">
                                                    <label style={{width: '100%'}}>{rowData.officialEmail}</label>
                                                        </div>
                                                        <div className="form-group">
                                                       <label>{rowData.EOJ}</label>
                                                       </div>
                                               
                                              
                                        </div>
                                    </div> 
                                </div> 
                            </div>
                        </div>
                    </div> 

                  
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                            <h4 className="mt-0 header-title">Account Details </h4>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>Account No :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.accountNumber}</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>IFSC Code :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.ifscCode}</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>Branch Name :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.branchName}</label>
                                                </div>
                                        </div> 
                                    </div> 
                                </div> 
                            </div> 
                            </div> 
                        </div> 
                    </div>
                    <div className="col-lg-12">
                        <div className="card" >
                        <div className="card-body " >
                                <h4 className="mt-0 header-title">File Uploads11 </h4>                               
                        <div className="row" >
                        {rowData.panImage!=null?(<div className="col-xl-4" >
                                <div className="card" >
                                    <div className="card-body" >
                                        <h4 className="mt-0 header-title">Pan Image</h4>                                        
                                        <img className="img-thumbnail w-100" style={{maxHeight: '300px',minHeight:'300px'}}  alt={imageDataURL+'/panImage/'+rowData.panImage} src={imageDataURL+'panImage/'+rowData.panImage} data-holder-rendered="true" />
                                        <br/>
                                       <div>
                                       <div className="text-left" style={{marginTop: '10px'}} >
                                        <a   onClick={()=>handleview(imageDataURL+'panImage/'+rowData.panImage)} target="_blank" className="btn btn-sm btn-info">View </a>
                                    </div>
                                    <div className="text-left" style={{marginTop: '-30px',marginLeft:'70px'}}>
                                         <button onClick={()=>downloadImage(imageDataURL+'panImage/'+rowData.panImage,rowData.panImage)}   className="btn btn-sm btn-info" download>Download</button>
                                         
                                    </div>
                                       </div>
                                    </div>
                                     
                                </div>
                            </div>):(<></>)

                            }
                            {rowData.aadharFrontImage!=null?(<div className="col-xl-4">
                                <div className="card">
                                    <div className="card-body" >
                                        <h4 className="mt-0 header-title">AADHAR front Image </h4>                                        
                                        <img className="img-thumbnail w-100" style={{maxHeight: '300px',minHeight:'300px'}} alt={imageDataURL+'/aadharFrontImage/'+rowData.aadharFrontImage} src={imageDataURL+'/aadharFrontImage/'+rowData.aadharFrontImage} data-holder-rendered="true" />
                                        <br/>
                                       <div>
                                       <div className="text-left" style={{marginTop: '10px'}}>
                                        <a  onClick={()=>handleview(imageDataURL+'/aadharFrontImage/'+rowData.aadharFrontImage)} target="_blank" className="btn btn-sm btn-info" >View</a>
                                    </div>
                                    <div className="text-left" style={{marginTop: '-30px',marginLeft:'70px'}}>
                                    <button onClick={()=>downloadImage(imageDataURL+'/aadharFrontImage/'+rowData.aadharFrontImage,rowData.aadharFrontImage)}   className="btn btn-sm btn-info" download>Download</button>
                                       
                                    </div>
                                       </div>
                                    </div>
                                     
                                </div>
                            </div>):(<></>)

                            }
                               {rowData.aadharFrontImage!=null?(<div className="col-xl-4">
                                <div className="card">
                                    <div className="card-body" >
                                    <h4 className="mt-0 header-title">AADHAR Back Image </h4>                                       
                                    <img className="img-thumbnail w-100" style={{maxHeight: '300px',minHeight:'300px'}} alt={imageDataURL+'/aadharBackImage/'+rowData.aadharBackImage} src={imageDataURL+'/aadharBackImage/'+rowData.aadharBackImage} data-holder-rendered="true" />
                                        <br/>
                                       <div>
                                       <div className="text-left" style={{marginTop: '10px'}}>
                                        <a  onClick={()=>handleview(imageDataURL+'/aadharBackImage/'+rowData.aadharBackImage)} target="_blank" className="btn btn-sm btn-info" >View</a>
                                    </div>
                                    <div className="text-left" style={{marginTop: '-30px',marginLeft:'70px'}}>
                                    <button onClick={()=>downloadImage(imageDataURL+'/aadharBackImage/'+rowData.aadharBackImage,rowData.aadharBackImage)}   className="btn btn-sm btn-info" download>Download</button>
                                       
                                    </div>
                                       </div>
                                    </div>
                                     
                                </div>
                            </div>):(<></>)

                            }
                            
                            {rowData.previousOffer!=null?(<div className="col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Previous Offer Letter </h4>                                        
                                        
                                        <button onClick={()=>downloadImage(imageDataURL+'/offerLetter/'+rowData.previousOffer,rowData.previousOffer)}   className="btn btn-sm btn-info" download>{rowData.previousOffer}</button>
                                       </div>
                                    </div>
                                     
                                </div>
                            ):(<></>)
                            }
                            
                           
{rowData.previousExperienceDoc!=null?(<div className="col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Previous Experience Letter </h4>                                        
                                        
                                        <button onClick={()=>downloadImage(imageDataURL+'/experienceLetter/'+rowData.previousExperienceDoc,rowData.previousExperienceDoc)}   className="btn btn-sm btn-info" download>{rowData.previousExperienceDoc}</button>
                                       </div>
                                    </div>
                                     
                                </div>
                            ):(<></>)
                            }


                    

{rowData.payslip1!=null?(<div className="col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Payslip 1 </h4>                                        
                                        
                                        <button onClick={()=>downloadImage(imageDataURL+'/payslip/'+rowData.payslip1,rowData.payslip1)}   className="btn btn-sm btn-info" download>{rowData.payslip1}</button>
                                       </div>
                                    </div>
                                     
                                </div>
                            ):(<></>)
                            }

                          
                        
                            {rowData.payslip2!=null?(<div className="col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Payslip 2 </h4>                                        
                                        <button onClick={()=>downloadImage(imageDataURL+'/payslip/'+rowData.payslip2,rowData.payslip2)}   className="btn btn-sm btn-info" download>{rowData.payslip2}</button>
                                       </div>
                                    </div>
                                     
                                </div>
                            ):(<></>)
                            }
                            {rowData.payslip3!=null?(<div className="col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Payslip 3 </h4>                                        
                                        <button onClick={()=>downloadImage(imageDataURL+'/payslip/'+rowData.payslip3,rowData.payslip3)}   className="btn btn-sm btn-info" download>{rowData.payslip3}</button>
                                       </div>
                                    </div>
                                     
                                </div>
                            ):(<></>)
                            } 
                    
             

                        </div>
                        
                       
                      

                         
                            </div>
                            

                        </div>


                    </div>
    </div>

    
    <Modal
      show={showModelViewer}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         EMPLOYEE RESUME
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form encType="multipart/form-data" onSubmit={empSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Resume Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter resume"
                autoFocus
                value={resumeTitle}
                onChange={e=>setresumeTitle(e.target.value)}
              
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Upload Resume</Form.Label>
              <FileUploader
        multiple={true}
        handleChange={handleFileChange}
        name="file"
        types={docFileTypes}
        maxSize={2}
      />
              
            </Form.Group>
           
            

            

            
           <center>
           <button  style={{backgroundColor:'#283179'}}    type="submit" className="btn btn-success waves-effect waves-light">
                     Submit
                     </button> 
           </center>
           
          </Form>
      </Modal.Body>
      <Modal.Footer>
      <button   onClick={() => setShowModelViewer(false)} type="button" className="btn btn-danger waves-effect waves-light">
                     Close
                     </button> 
      </Modal.Footer>
    </Modal>


    <Modal
      show={photoModelViewer}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         EMPLOYEE PROFILE
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form onSubmit={Submit}>
      
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Upload Photo</Form.Label>
              <FileUploader
        multiple={true}
        handleChange={handleFile}
        name="file"
        types={imgFileTypes}
        maxSize={2}
      />
            </Form.Group>
           
            

            

            
           <center>
           <button  style={{backgroundColor:'#283179'}}    type="submit" className="btn btn-success waves-effect waves-light">
                     Submit
                     </button> 
           </center>
           
          </Form>
      </Modal.Body>
      <Modal.Footer>
      <button   onClick={() => setphotoModelViewer(false)} type="button" className="btn btn-danger waves-effect waves-light">
                     Close
                     </button> 
      </Modal.Footer>
    </Modal>


    <Modal
      show={showModelView}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Image View
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <img style={{maxWidth:'100%',minWidth:'100%'}} src={imgViewFile} />
      </Modal.Body>
      <Modal.Footer>
      <button   onClick={() => setshowModelView(false)} type="button" className="btn btn-danger waves-effect waves-light">
                     Close
                     </button> 
      </Modal.Footer>
    </Modal>
</div>
   );
}
export default Profile;