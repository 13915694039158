import React from "react";
import { BrowserRouter, Route, Switch, HashRouter,Redirect } from 'react-router-dom';
import TaxinfoComponent from '../index'
const TaxinfoRoute=()=>
{ return (
        <Switch>
            <Route  path="/corporate/taxinfo/"  component={TaxinfoComponent} />
            <Route path='*' render={() => (<Redirect to="/pagenotfound" />)}  />
        </Switch>
    );

}
export default TaxinfoRoute;