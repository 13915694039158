import React,{ ChangeEvent,useState,useEffect} from "react";
import {useDispatch,useSelector} from 'react-redux';
import { useHistory,useParams  } from "react-router-dom";
import {apiPost,apiFormDatePost} from '../api/apiCommon'
import profileImg from '../assets/img/man-icon.png';
import logoimg from '../assets/img/logo.png';
import 'react-alert-confirm/lib/style.css';
import  '../assets/css/userprofile.css';
import image from '../assets/img/logo.png'




const  Userprofile=()=>
{
	const[pageOn,setPageON]=useState(true);
    const { id } = useParams();
    const reduxState = useSelector(state=>state);
    const dispatch = useDispatch();
    const history = useHistory();
	
    useEffect(() => {
      getEmployeeData();
	setTimeout(() => { setPageON(false)

        }, 30 * 60 * 1000);
console.log(setTimeout);
      }, []);
  
    const [rowData, setEmployeedata] = useState({});
    console.log(pageOn);

	/* const getEmployeeData=(params:any)=>
	{

	   history.push("/userprofile/"+params.value)

	   console.log(params.value);

	} */
  const getEmployeeData= async()=>
  {

      try
      {
          const returnapi = await apiPost('getemployeedetails', {employeeUID:id
          });
       
          setEmployeedata(returnapi.data.empDetails[0])
          console.log(returnapi.data.empDetails[0]);
      }
      catch(error) 
      {
         console.log(error);
      }
  }
  const [defaulProfileIMG, setDefaulProfileIMG] = useState(profileImg);
  const [defaulLogoIMG, setDefaulLogoIMG] = useState(logoimg);

	if(pageOn == true){
		return(  
	
    <>
       <header id="topnav">
        <div className="topbar-main">
          <div className="container-fluid newemployeeHeader">
            <div className="logo">
              <a href="#" className="logo">
                <img src={defaulLogoIMG} alt="" className="logo-large" style={{ height: '48px' }} />
              </a>
            </div>
            <div className="menu-extras topbar-custom">
              <ul className="list-inline float-right mb-0">
                <li className="menu-item list-inline-item">
                  <a className="navbar-toggle nav-link">
                    <div className="lines">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </header>

      <div id="user-profile" className="profile-head">
        <div className="container-fluid">
          {/* first row */}
          <div className="row">
            <div className="col-sm-12 col-xl-8">
              <div className="media d-flex m-1">
                <div className="align-left p-1">
                  <a href="#" className="profile-image">
                    <img src={defaulProfileIMG}  className="rounded-circle img-border" alt="Card image" width="120px" height="120px" />
                  </a>
                </div>
                <div className="media-body text-left mt-1">
                  <h3 className="font-large-1 white">{rowData.name}</h3>
                  <p className="white">
                    <i className="fa fa-id-badge" aria-hidden="true"></i> {rowData.employmentType}
                  </p>
                  <ul className="list-inline">
                    <li className="pr-1 line-height-1">
                      <a href="#" className="white">
                        <i className="fa fa-envelope" aria-hidden="true"></i> {rowData.personalEmail}
                      </a>
                    </li>
                    {/* <!-- <li className="pr-1 line-height-1">
                      <a href="#" className="font-medium-4 white">
                        <i className="fa fa-twitter-square" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="line-height-1">
                      <a href="#" className="font-medium-4 white">
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                    </li> --> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper" style={{ paddingTop: '80px' }}>
        <div id="user-profile">
          {/* first row ends */}
          {/* second Row */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-lg-5 col-md-12">
                <div className="card">
                  <div className="card-header pb-0">
                    <div className="card-title-wrap bar-primary">
                      <h4 className="card-title">Personal Details</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="card-content">
                    <div className="card-body p-0 pt-0 pb-1">
                      <ul>
                        {/* <li><b>Blood Group:</b> O+</li> */}
                        <li><b>Date Of Birth:</b> {rowData.DOB}</li>
                        {/* <!-- <li><b>Marital Status:</b> Unmarried</li> --> */}
                        <li><b>Address:</b> {rowData.address}</li>
                        <li><b>Contact No:</b> {rowData.parentsContactNo}</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Skills</h4>
                  </div>
                  <div className="card-content">
                    <div className="card-body pt-0 pb-3">
                      <h6 className="text-bold-600">Self Management</h6>
                      <div className="progress progress-sm mt-1 mb-0 box-shadow-2">
                        <div className="progress-bar bg-gradient-x-danger" role="progressbar" style={{ width: '80%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <h6 className="text-bold-600">Team Work</h6>
                      <div className="progress progress-sm mt-1 mb-0 box-shadow-2">
                        <div className="progress-bar bg-gradient-x-danger" role="progressbar" style={{ width: '80%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <h6 className="text-bold-600">Communication</h6>
                      <div className="progress progress-sm mt-1 mb-0 box-shadow-2">
                        <div className="progress-bar bg-gradient-x-danger" role="progressbar" style={{ width: '80%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 col-lg-7 col-md-12">
                {/* Project Timeline div starts */}
                <div id="timeline1">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-block">
                        <div className="timeline">
                          <h4>Work Profile & Contact Information</h4>
                          <hr />
                          <div className="row">
                            <div className="col-lg-6">
                              <h6>Employee ID</h6>
                              <p>
                                <i className="fa fa-id-badge" aria-hidden="true"></i> {rowData.empCode}
                              </p>
                              {/* ... Other details ... */}
                            </div>
                            <div className="col-lg-6">
                              <h6>Employee Email</h6>
                              <p>
                                <i className="fa fa-envelope" aria-hidden="true"></i> {rowData.officialEmail}
                              </p>
                              {/* ... Other details ... */}
                            </div>
                            <div className="col-lg-6">
                              <h6>Employee Phone</h6>
                              <p>
                                <i className="fa fa-mobile" aria-hidden="true"></i> {rowData.contactNo}
                              </p>
                              {/* ... Other details ... */}
                            </div>
                          </div>
                          <h4>Other Information</h4>
                          <hr />
                          <div className="row">
                            <div className="col-lg-6">
                              <h6>Join Date</h6>
                              <p>
                                <i className="fa fa-calendar" aria-hidden="true"></i> {rowData.DOJ}
                              </p>
                              {/* ... Other details ... */}
                            </div>
                            <div className="col-lg-6">
                              <h6>Interest</h6>
                              <p>
                                <i class="fa fa-book" aria-hidden="true"></i> Software Development
                              </p>
                              {/* ... Other details ... */}
                            </div>
                            <div className="col-lg-6">
                              <h6>Qualification</h6>
                              <p>
                                <i class="fa fa-fa fa-graduation-cap" aria-hidden="true"></i> {rowData.qualification}
                              </p>
                              {/* ... Other details ... */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Project Timeline div ends */}
              </div>
            </div>
          </div>
          {/* Second row ends */}
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              Bixware Internaluse by{' '}
              <a href="https://bixware.com" target="_blank" style={{ fontWeight: 'bold' }}>
                Bixware Technologies
              </a>{' '}
              © 2023. All Rights Reserved
            </div>
          </div>
        </div>
      </footer>
    </>
   );
		
	}
	else{
		return( 
			<>
			<div className="expired-notice">
			  <span >Page Expired!!!</span>
			  {/* <p>Please select a future date and time.</p> */}
			</div>
			</>
	);
		
	}
   
   
}

export default Userprofile;