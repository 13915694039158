import React from "react";
import { BrowserRouter, Route, Switch, HashRouter,Redirect } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Dashboard from '../component/dashboard/dashboard';
import NewlyOffered from '../component/newlyoffered/newlyoffered';
import InternShip from '../component/internship/internship';
import OnRoll from '../component/onroll/onroll';
import Exit from '../component/exit/exit';
import Preference from '../component/preference/preference';
import EmployeeView from '../employeeview/employeeview';
import PageNotFound from '../../extraPages/pagenotfound'
const corporateComponent=()=>
{  return (
    <><>
        <Header />
        <Switch>
            <Route exact path="/corporate/admin/dashboard" component={Dashboard} />
            <Route exact path="/corporate/admin/newlyoffered" component={NewlyOffered} />
            <Route exact path="/corporate/admin/internship" component={InternShip} />
            <Route exact path="/corporate/admin/onroll" component={OnRoll} />
            <Route exact path="/corporate/admin/exit" component={Exit} />
            <Route exact path="/corporate/admin/viewemployee/:id" component={EmployeeView} />
            <Route exact path="/corporate/admin/empreference" component={Preference} />
            <Route path='*' render={() => (<Redirect to="/pagenotfound" />)}  />
        </Switch>
    </><Footer /></>
    
    
   );
}

export default corporateComponent;