import React from "react";
import { BrowserRouter, Route, Switch, HashRouter,Redirect } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Dashboard from './dashboard/dashboard';
import EditEmployee from "./editemployee/editemployee";
import EmployeeView from '../employeeview/employeeview';
import NewlyOffered from "./newlyoffered/newlyoffered";
const ServerComponent=()=>
{  return (
    <><>
        <Header />
        <Switch>
            <Route exact path="/corporate/server/dashboard" component={Dashboard} />
            <Route exact path="/corporate/server/newlyoffered" component={NewlyOffered} />
            <Route exact path="/corporate/server/EditEmployee/:id" component={EditEmployee} /> 
            <Route exact path="/corporate/server/viewemployee/:id" component={EmployeeView} /> 
            <Route path='*' render={() => (<Redirect to="/pagenotfound" />)}  />
        </Switch>
    </><Footer /></>
    
    
   );
}

export default ServerComponent;