import React from "react";
import { BrowserRouter, Route, Switch, HashRouter,Redirect } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Dashboard from './dashboard/dashboard';
import InternShip from './internship/internship';
import OnRoll from './onroll/onroll';
import Exit from './exit/exit';
import EditEmployee from "./editemployee/editemployee";
import EmployeeView from '../employeeview/employeeview';
const TaxinfoComponent=()=>
{  return (
    <><>
        <Header />
        <Switch>
            <Route exact path="/corporate/taxinfo/dashboard" component={Dashboard} />
            <Route exact path="/corporate/taxinfo/internship" component={InternShip} />
            <Route exact path="/corporate/taxinfo/onroll" component={OnRoll} />
            <Route exact path="/corporate/taxinfo/exit" component={Exit} />
            <Route exact path="/corporate/taxinfo/EditEmployee/:id" component={EditEmployee} />
         <Route exact path="/corporate/taxinfo/viewemployee/:id" component={EmployeeView} /> 
            <Route path='*' render={() => (<Redirect to="/pagenotfound" />)}  />
        </Switch>
    </><Footer /></>
    
    
   );
}

export default TaxinfoComponent;