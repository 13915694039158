import React from "react";
import { BrowserRouter, Route, Switch, HashRouter,Redirect } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Dashboard from './dashboard/dashboard';
import InternShip from './internship/internship';
import OnRoll from './onroll/onroll';
import Exit from './exit/exit';

import EditEmployee from "./editemployee/editemployee";
import EmployeeView from '../employeeview/employeeview';
import NewlyOffered from "./newlyoffered/newlyoffered";
import TimeSheet from "./TimeSheet/timesheet";
import ViewTimesheet from "./TimeSheet/viewtimesheet";
const HRComponent=()=>
{  return (
    <><>
        <Header />
        <Switch>
            <Route exact path="/corporate/hr/dashboard" component={Dashboard} />
            <Route exact path="/corporate/hr/newlyoffered" component={NewlyOffered} />
            <Route exact path="/corporate/hr/internship" component={InternShip} />
            <Route exact path="/corporate/hr/onroll" component={OnRoll} />
            <Route exact path="/corporate/hr/exit" component={Exit} />
            <Route exact path="/corporate/hr/timesheet" component={TimeSheet} />
            <Route exact path="/corporate/hr/EditEmployee/:id" component={EditEmployee} /> 
            <Route exact path="/corporate/hr/viewemployee/:id" component={EmployeeView} /> 
            <Route exact path="/corporate/hr/viewtimesheet/:id" component={ViewTimesheet} /> 
            <Route path='*' render={() => (<Redirect to="/pagenotfound" />)}  />
        </Switch>
    </><Footer /></>
    
    
   );
}

export default HRComponent;