import React  from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { apiPost } from "../../../api/apiCommon";
const Dashboard =()=>
{
    const reduxState=useSelector(state=>state);
        const [rowData, setrowData] = React.useState(['00:00:00']);
        const [logButton, setlogButton] = React.useState(['LOG IN']);
        const [lunchVisible, setlunchVisible] = React.useState(['none']);
        const [breakButton, setbreakButton] = React.useState(['BREAK AT']);
        const [isRunning, setIsRunning] = React.useState(false);
        
            // state to store time
            const [time, setTime] = React.useState(0);
          
            // state to check stopwatch running or not
          
          
            useEffect(() => {
              getWeek();
              getdate();
              let intervalId;
              if (isRunning) {
                // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
                intervalId = setInterval(() => setTime(time + 1), 10);
              }
              return () => clearInterval(intervalId);
            }, [isRunning, time]);
          
            // Hours calculation
            const hours = Math.floor(time / 360000);
          
            // Minutes calculation
            const minutes = Math.floor((time % 360000) / 6000);
          
            // Seconds calculation
            const seconds = Math.floor((time % 6000) / 100);
          
            // Milliseconds calculation
            const milliseconds = time % 100;
          
            const Stop = () => {
              setIsRunning(!isRunning);
            };
          
            // Method to reset timer back to 0
            const reset = () => {
              setTime(0);
            };
    
  
        
        const login=async ()=>
        {
         
                setlogButton('LOG OFF');
                if(logButton=='LOG IN')
                {
                  setIsRunning(!isRunning);
                  var today = new Date(),
                  time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
                  setrowData(time); 
                  setlunchVisible('block'); 
                  const response= await  apiPost('admin/getemployeeloggedin', {userUID:reduxState.userReducer.userUID,
                          roleUID:reduxState.userReducer.roleUID});
                          console.log(response);
                }
                if(logButton=='LOG OFF')
                {
                  Stop();
                  var today = new Date(),
                  time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
                  setrowData(time);
                 
                  setlunchVisible('none'); 
                  
                
                  const response= await  apiPost('admin/getemployeeloggedout', {userUID:reduxState.userReducer.userUID,
                          roleUID:reduxState.userReducer.roleUID});
                         console.log(response);
                }
              

                       
      
        };
        const [LunchRunning, setLunchRunning] = React.useState(false);
        
            // state to store time
            const [Lunchtime, setLunchtime] = React.useState(0);
          
            // state to check stopwatch running or not
          
          
            useEffect(() => {
              let intervalId;
              if (LunchRunning) {
                // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
                intervalId = setInterval(() => setLunchtime(Lunchtime + 1), 10);
              }
              return () => clearInterval(intervalId);
            }, [LunchRunning, Lunchtime]);
          
            // Hours calculation
            const lunchhours = Math.floor(Lunchtime / 360000);
          
            // Minutes calculation
            const lunchminutes = Math.floor((Lunchtime % 360000) / 6000);
          
            // Seconds calculation
            const lunchseconds = Math.floor((Lunchtime % 6000) / 100);
          
            // Milliseconds calculation
            const lunchmilliseconds = Lunchtime % 100;
          
       
            const LunchStop = () => {
              setLunchRunning(!Lunchtime);
            };
          
            // Method to reset timer back to 0
            const resett = () => {
              setTime(0);
            };
    
        const lunch=async ()=>
        {
               
        
          if(breakButton=='BREAK AT')
          {
            setLunchRunning(!Lunchtime);
            setbreakButton('BREAK END');
               
            const response= await  apiPost('admin/getemployeelunchat', {userUID:reduxState.userReducer.userUID,
                    roleUID:reduxState.userReducer.roleUID});
                   console.log(response);
          }
          if(breakButton=='BREAK END')
          {
            LunchStop();
                var today = new Date(),
                time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
                setrowData(time);
              
                setlunchVisible('none'); 
               
                const response= await  apiPost('admin/getemployeelunend', {userUID:reduxState.userReducer.userUID,
                        roleUID:reduxState.userReducer.roleUID});
                       console.log(response);
          }
                
      
        };

        const [date , setDate] = React.useState(['']);
        const [week , setWeek] = React.useState(['']);
         const getWeek = () => 
         {
          let date = new Date();
          let day = date.toLocaleString('en-IN', {weekday: 'long'});       
          setWeek(day);
         }
         const getdate = () => 
         {
          //var strSplitDate = String(strDate).split(' ');
          var date = new Date();
          // alert(date);
          var dd = date.getDate();
          //const date = new Date(2009, 10, 10);  // 2009-11-10
          var month = date.toLocaleString('default', { month: 'long' });
           var full = dd +" "+ month ;
          /* var mm = date.getMonth() + 1; //January is 0!
          var yyyy = date.getFullYear();
          if (dd < 10) {
              dd = '0' + dd;
          }
          if (mm < 10) {
              mm = '0' + mm;
          }
          date =  dd + "-" + mm + "-" + yyyy; */
          setDate(full);
         }
       
   return(
    <div className="wrapper">
            <div className="container-fluid">
            
            <div className="row">
            <div className="col-sm-12">
            <div className="page-title-box">
            <div className="btn-group pull-right">
            <ol className="breadcrumb hide-phone p-0 m-0">
            </ol>
            </div>
            <h4 className="page-title">Dashboard</h4>
            </div>
            </div>
            </div>

            <div className="row">
            <div className="col-12">
            <div className="card">
            <div className="card-body" style={{minHeight:'350px'}}>
            <div className="row">
            <div className=" col-sm ">
                <center>
                <span className="etdy">
                  <Form.Label >
                               {week}
                </Form.Label></span>
                
            <br/><span className="edate">
              <Form.Label >
                               {date}
              </Form.Label></span></center>

          <div className="col-xs"> 
                <div className="col-10 eOverAllTimeDiv">
                <center>
                <span className="eOverAllTime">    
                         <Form.Label >
                               {rowData}
                          </Form.Label>
                </span><br/><span className="eOverAllTimeText">Log Time</span>
                </center>
                </div>
                    
            </div>
            <div className=" col-sm">
            <div className="row">
                <div className=" col-6">
                <span className="eloginBtn"><button  onClick={login} type="button" class="btn btn-success btnz">{logButton}</button></span>
                </div>
                <div className=" col-6">
                <span className="eloginBtn"><button style={{ display: lunchVisible }} onClick={lunch} type="button" class="btn btn-danger btnz">{breakButton}</button></span>
                </div>
             </div> 
            
            </div>
            </div>
          
      
            <div className=" col-sm"> 
                <div className="col-6 eOverWorkTimeDiv">
                <center>
                <span className="eOverWorkTime">
                      <Form.Label >
                              {hours}:{minutes.toString().padStart(2, "0")}:
                              {seconds.toString().padStart(2, "0")}:
                              {milliseconds.toString().padStart(2, "0")}
                      </Form.Label>
                </span><br/><span className="eOverAllTimeText">Work Time</span>
                </center>
                </div>
                    
                
            </div>
            <div className=" col-sm"> 
                <div className="col-6 eOverBreakTimeDiv">
                <center>
                <span className="eOverBreakTime">
                     <Form.Label >
                               {lunchhours}:{lunchminutes.toString().padStart(2, "0")}:
                               {lunchseconds.toString().padStart(2, "0")}:
                               {lunchmilliseconds.toString().padStart(2, "0")}
                      </Form.Label>
                </span><br/><span className="eOverAllTimeText">Break Time</span>
                </center>
                </div>
                    
                
            </div>
            
     
            </div>
            </div>
            </div>
            </div>
            </div>
            
            </div>
            </div>);
}
export default Dashboard;